import {
  bademiljoeImgUri,
  blinkhusIconUri,
  blinkhusImgUri,
  boligpartnerIconUri,
  boligpartnerImgUri,
  bolistIconImgUri,
  bolistLogoImgUri,
  byggerietImgUri,
  byggtorgetIconUri,
  byggtorgetImgUri,
  comfortIconUri,
  comfortImgUri,
  fargerikeIconUri,
  fargerikeLogoImgUri,
  kuloerFaviconUri,
  kuloerIconUri,
  kuloerImgUri,
  mestergruppenIconUri,
  mestergruppenImgUri,
  mesterhusIconUri,
  mesterhusImgUri,
  nordbohusIconUri,
  nordbohusImgUri,
  pretreIconImgUri,
  pretreImgUri,
  saltdalshyttaIconUri,
  saltdalshyttaImgUri,
  steddyIconUri,
  steddyImgUri,
  systemhusIconUri,
  systemhusImgUri,
  wifaIconUri,
  wifaImgUri,
  xlByggIconUri,
  xlByggImgUri,
} from '@assets/images';
import { BrandStylesConfig } from '@dap-common/types';
import { colors, commonText } from '../theme';

export const brandStylesConfig: BrandStylesConfig = {
  default: {
    logo: mestergruppenImgUri,
    icon: mestergruppenIconUri,
    favicon: mestergruppenIconUri,
  },
  xlbygg: {
    logo: xlByggImgUri,
    icon: xlByggIconUri,
    favicon: xlByggIconUri,
    url: 'https://www.xl-bygg.no/',
    colors: {
      main: 'rgb(210, 180, 120)',
      light: 'rgba(210, 180, 120, 0.2)',
      contrastText: commonText.primary,
    },
  },
  bolist: {
    logo: bolistLogoImgUri,
    icon: bolistIconImgUri,
    favicon: bolistIconImgUri,
    colors: {
      main: 'rgb(255, 94, 10)',
      light: 'rgba(255, 94, 10, 0.20)',
      contrastText: commonText.primary,
    },
  },
  xlbyggsverige: {
    logo: xlByggImgUri,
    icon: xlByggIconUri,
    favicon: xlByggIconUri,
    url: 'https://www.xlbygg.se/',
    colors: {
      main: 'rgb(210, 180, 120)',
      light: 'rgba(210, 180, 120, 0.2)',
      contrastText: commonText.primary,
    },
  },
  mesterhus: {
    logo: mesterhusImgUri,
    icon: mesterhusIconUri,
    favicon: mesterhusIconUri,
    url: 'https://www.mesterhus.no/',
    colors: {
      main: '#FFFFFF',
      contrastText: commonText.primary,
    },
  },
  byggtorget: {
    logo: byggtorgetImgUri,
    icon: byggtorgetIconUri,
    favicon: byggtorgetIconUri,
    url: 'https://www.byggtorget.no/',
    colors: {
      main: 'rgb(0, 107, 45)',
      light: 'rgba(0, 107, 45, 0.2)',
      contrastText: commonText.primary,
    },
  },
  systemhus: {
    logo: systemhusImgUri,
    icon: systemhusIconUri,
    favicon: systemhusIconUri,
    url: 'https://www.systemhus.no/',
    colors: {
      main: '#FFF7ED',
      contrastText: commonText.primary,
    },
  },
  blinkhus: {
    logo: blinkhusImgUri,
    icon: blinkhusIconUri,
    favicon: blinkhusIconUri,
    url: 'http://www.blink-hus.no/',
    colors: {
      main: '#295281',
      contrastText: colors.white,
    },
  },
  nordbohus: {
    logo: nordbohusImgUri,
    icon: nordbohusIconUri,
    favicon: nordbohusIconUri,
    url: 'https://nordbohus.no/',
    colors: {
      main: '#1F3F5D',
      contrastText: colors.white,
    },
  },
  saltdalshytta: {
    logo: saltdalshyttaImgUri,
    icon: saltdalshyttaIconUri,
    favicon: saltdalshyttaIconUri,
    url: 'https://www.saltdalshytta.no/',
    colors: {
      main: '#E3E0DA',
      contrastText: commonText.primary,
    },
  },
  pretre: {
    logo: pretreImgUri,
    icon: pretreIconImgUri,
    favicon: pretreImgUri,
    url: 'https://www.pretre.no/',
  },
  byggeriet: {
    logo: byggerietImgUri,
    icon: byggerietImgUri,
    favicon: byggerietImgUri,
    url: 'https://byggeriet.no/',
  },
  rot: {
    logo: steddyImgUri,
    icon: steddyIconUri,
    favicon: steddyIconUri,
    colors: {
      main: '#273830',
      contrastText: colors.white,
    },
  },
  mestergruppen: {
    logo: mestergruppenImgUri,
    icon: mestergruppenIconUri,
    favicon: mestergruppenIconUri,
  },
  kuloer: {
    logo: kuloerImgUri,
    icon: kuloerIconUri,
    favicon: kuloerFaviconUri,
    url: 'https://kulornorge.no/',
  },
  fargerike: {
    logo: fargerikeLogoImgUri,
    icon: fargerikeIconUri,
    favicon: fargerikeIconUri,
    url: 'https://www.fargerike.no/',
  },
  bademiljoe: {
    logo: bademiljoeImgUri,
    icon: mestergruppenIconUri,
    favicon: mestergruppenIconUri,
    url: 'https://www.bademiljo.no/',
    colors: {
      main: '#333e48',
      contrastText: colors.white,
    },
  },
  boligpartner: {
    logo: boligpartnerImgUri,
    icon: boligpartnerIconUri,
    favicon: boligpartnerIconUri,
    url: 'https://boligpartner.no/',
    colors: {
      main: '#3a6e89',
      contrastText: colors.white,
    },
  },
  comfort: {
    logo: comfortImgUri,
    icon: comfortIconUri,
    favicon: comfortIconUri,
    url: 'https://www.comfort.no/',
    colors: {
      main: colors.white,
      contrastText: colors.black,
    },
  },
  wifa: {
    logo: wifaImgUri,
    icon: wifaIconUri,
    favicon: wifaIconUri,
    url: 'https://wifa.no/',
  },
};
