import { BrandConfigurationRequest, ModuleType, DocumentType } from '@dap-sanity/types';
import { groqQuery } from '@shared/utils';

export const getBrandConfiguration = ({ brandKey }: BrandConfigurationRequest) =>
  groqQuery({
    filters: [`_type=="${DocumentType.BrandConfiguration}" && brand=="${brandKey}"`],
    projections: [
      `'${ModuleType.newsModule}':modules[_type match "${ModuleType.newsModule}"][0]{
            _type,
            show
        }`,
      `'${ModuleType.eventsModule}':modules[_type match "${ModuleType.eventsModule}"][0]{
            _type,
            show
        }`,
      `'${ModuleType.servicesModule}':modules[_type match "${ModuleType.servicesModule}"][0]{
            _type,
            show,
            header,
            infoText,
        }`,
      `'${ModuleType.proffModule}':modules[_type match "${ModuleType.proffModule}"][]->{
            _id,
            _type,
            show,
            "slug": module_slug.current,
            header,
            frontPageProffImage,
        }`,
      `'${ModuleType.fremdriftModule}':*[_type match "${ModuleType.fremdriftModule}" &&  (_id in ^.modules[]._ref) && count((categories[]->brand)["${brandKey}" in @]) > 0]{
            _id,
            _type,
            show,
            "slug": module_slug.current,
            header,
        }`,
      `'${ModuleType.orderModule}':*[_type == "${ModuleType.orderModule}" && "${brandKey}" in brand][]{
            _id,
            _type,
            show,
            "slug": module_slug.current,
            header,
        }`,
      `'${ModuleType.mosaicModule}':modules[_type match "${ModuleType.mosaicModule}"][]->{
            _id,
            _type,
            show,
            "slug": module_slug.current,
            header,
        }`,
      `'${ModuleType.helpCentreModule}':modules[_type match "${ModuleType.helpCentreModule}"][0]{
            _type,
            show
        }`,
    ],
    slice: { start: 0 },
  });

export const getBrandDashboardQuery = ({ brandKey }: BrandConfigurationRequest) =>
  groqQuery({
    filters: [`_type=="${DocumentType.Dashboard}" && _id=="${brandKey}-dashboard"`],
    projections: [
      `...,
      mainContent[]{
        ...,
        links[]{
          ...,
          "image": image.asset->
        },
        variant[]{
          ...,
          "image": image.asset->
        }
      }`,
    ],
    slice: { start: 0 },
  });
