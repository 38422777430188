import { Sort } from '@mui/icons-material';
import { alpha, Box, Button, Stack, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { messages } from '@dap-common/i18n';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChildrenBlur from '../ChildrenBlur';

interface Props {
  children: React.ReactElement;
  buttonVariant?: 'text' | 'outlined';
  textColor?: 'inherit' | 'primary';
  border?: string;
  contentPlacement?: 'bottomLeft' | 'bottomRight';
}

const ExpandMenu = ({
  children,
  buttonVariant = 'text',
  textColor = 'inherit',
  border = 'none',
  contentPlacement = 'bottomRight',
}: Props) => {
  const { t } = useTranslation();
  const { palette, zIndex, shape } = useTheme();
  const [isActive, setIsActive] = useState<boolean>(false);

  return (
    <Box onMouseLeave={() => setIsActive(false)} onMouseEnter={() => setIsActive(true)}>
      <Button variant={buttonVariant} color={textColor} startIcon={<Sort />} sx={buttonSx(border)}>
        <Typography variant="body4">{t(messages.common.location.sort.name)}</Typography>
      </Button>

      {isActive && (
        <Stack
          position="absolute"
          right={contentPlacement === 'bottomRight' ? 0 : undefined}
          bgcolor={palette.common.white}
          borderRadius={`${shape.borderRadius}`}
          border={`1px solid ${alpha(palette.grey[900], 0.1)}`}
          color={palette.common.black}
          padding={'1rem 0 '}
          zIndex={zIndex.tooltip}
        >
          <ChildrenBlur onBlur={() => setIsActive(false)}>{children}</ChildrenBlur>
        </Stack>
      )}
    </Box>
  );
};

const buttonSx = (border: string): SxProps<Theme> => ({
  height: '100%',
  border,
});

export default ExpandMenu;
