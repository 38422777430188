import { Link, Typography, useTheme, Tooltip } from '@mui/material';
import { LinkListItem } from '@dap-sanity/types';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface Props {
  model: LinkListItem;
}

export function LinkCard({ model }: Props) {
  const { palette } = useTheme();

  // TODO ASO Make title a required field in Sanity
  const _asoTitle = model.title ?? 'Tittel mangler';

  return (
    <Tooltip title={model?.subtitle ?? ''} placement="bottom" arrow>
      <Link
        href={model.url}
        target="_blank"
        rel="noopener noreferrer"
        key={model._key}
        position="relative"
        sx={{
          color: palette.common.white,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          // Using container relative units to solve width that keeps 4 items in viewport
          width: 'calc(25cqw - 12px)',
          minWidth: '240px',
          maxHeight: '160px',
          gap: 2,
          paddingInline: 4,
          paddingBlock: 2,
          aspectRatio: 260 / 144,
          borderRadius: 1,
          background: palette.grey[800],
          textDecoration: 'none',

          '& svg': {
            transition: 'transform 250ms ease-in',
          },

          '&:hover svg': {
            transform: 'translateX(6px)',
            transition: 'transform 250ms ease-out',
          },
        }}
      >
        <Typography variant="h4" component="span">
          {_asoTitle}
        </Typography>
        <ArrowForwardIcon />
      </Link>
    </Tooltip>
  );
}
