import { Card, CardProps } from '@mui/material';
import { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface Props extends CardProps {
  children: ReactNode;
  to: LinkProps['to'];
  sx?: CardProps['sx'];
  variant?: 'outlined' | 'elevation' | 'default' | 'primary';
}

export default function PreviewCard({ children, to, sx, variant = 'outlined', ...props }: Props) {
  return (
    <Card
      component={Link}
      variant={variant}
      sx={{
        flexGrow: 1,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textDecoration: 'none',
        gap: 1,
        '& img': {
          transition: 'transform 350ms ease-in-out',
        },
        '&:hover img': {
          transform: 'scale(1.05)',
        },
        ...sx,
      }}
      {...props}
      to={to}
    >
      {children}
    </Card>
  );
}
