import { Box, Button, Card, Link, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PreviewSanityImage from '../previewContent/PreviewSanityImage';
import { eventCardImageHeight } from './eventConstants';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { Link as RouterLink } from 'react-router-dom';
import { PropsWithChildren } from 'react';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { maxLinesSx } from '@shared/utils';
import { messages } from '@dap-common/i18n';
import { OverflowingText } from '@shared/components';

interface EventCardProps {
  title: string;
  intro: string;
  date: string;
  registrationUrl?: string;
  registrationEmail?: string;
  time?: string;
  place?: string;
  href?: string;
  image?: SanityImageSource;
}

export default function EventCard({
  image,
  title,
  date,
  intro,
  registrationUrl,
  registrationEmail,
  time,
  place,
  href,
}: EventCardProps) {
  const { t } = useTranslation(['events']);

  return (
    <Card
      sx={{
        p: 0,
        display: 'flex',
        flexDirection: 'column',
        '& img': {
          transition: 'transform 350ms ease-in-out',
        },
        '&:hover img': {
          transform: 'scale(1.05)',
        },
      }}
    >
      <Stack spacing={2} sx={{ overflow: 'hidden' }}>
        <LinkWrapper href={href}>
          {image ? (
            <PreviewSanityImage height={eventCardImageHeight} image={image} noBorderRadius />
          ) : (
            <Box width="100%" height={eventCardImageHeight} sx={{ backgroundColor: 'grey.100' }} />
          )}
        </LinkWrapper>
      </Stack>

      <Stack
        spacing={2}
        sx={{
          justifyContent: 'space-between',
          flexGrow: '1',
        }}
      >
        <Box>
          <LinkWrapper href={href}>
            <OverflowingText variant="h6" component="p" gutterBottom tooltipText={title}>
              {title}
            </OverflowingText>
            <Typography variant="body2" sx={{ ...max2LinesTextSx, m: 0 }} paragraph>
              {intro}
            </Typography>
          </LinkWrapper>
        </Box>

        <Stack spacing={2}>
          {(registrationUrl || registrationEmail) && (
            <Button
              disableElevation
              variant="contained"
              color="primary"
              size="small"
              href={registrationUrl || `mailto:${registrationEmail}`}
              target={registrationUrl ? '_blank' : '_self'}
            >
              {t(messages.events.registration)}
            </Button>
          )}
          <Stack spacing={0.5}>
            {place && (
              <Typography
                variant="body2"
                sx={{ display: 'flex', alignItems: 'flex-start', gap: 0.5 }}
              >
                <PlaceOutlinedIcon fontSize="small" color="primary" />
                {place}
              </Typography>
            )}

            {time && (
              <Typography
                variant="body2"
                sx={{ display: 'flex', alignItems: 'flex-start', gap: 0.5 }}
              >
                <AccessTimeOutlinedIcon fontSize="small" color="primary" />
                {time}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}

function LinkWrapper({ href, children }: { href: string | undefined } & PropsWithChildren) {
  if (href) {
    return (
      <Link
        component={RouterLink}
        underline="none"
        to={href}
        sx={{
          color: 'inherit',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        {children}
      </Link>
    );
  }
  return children;
}

const max2LinesTextSx = maxLinesSx(2);
