import { appLogos } from '@assets/appLogos';
import { AppDetailed, BrandV2, CompleteApp, UserData } from '@dap-admin/types';
import { getSelectedRoles } from './rolesUtils';
import { AppType } from '@dap-common/types';

export const mapAppToCompleteApp = ({
  key,
  name,
  url,
  config,
  ...rest
}: AppDetailed): CompleteApp => ({
  key,
  name: config?.name || name,
  url: config?.url || url || '',
  logoUrl: appLogos[key],
  default: !!rest.default,
});

export function getUserAppsForBrand(brandApps: BrandV2['apps'], userApps: UserData['apps']) {
  return brandApps
    .filter((app) => userApps.some((userApp) => userApp.key === app.key))
    .map(mapAppToCompleteApp);
}

export enum ConfigurationStatus {
  Incomplete = 'Incomplete',
  Complete = 'Complete',
}

export interface BrandDetailsConfiguration {
  brand: ConfigurationStatus;
  dataowner: ConfigurationStatus;
  location: ConfigurationStatus;
  brandAdminApp: ConfigurationStatus;
}

export function getBrandDetailsConfigurationV2(brand: BrandV2): BrandDetailsConfiguration {
  const brandHasAtLeastOneBrandAdminRole = getSelectedRoles(brand.brandRoles).some(
    (role) => role.isAdmin
  );
  const brandHasAtLeastOneDataownerAdminRole = getSelectedRoles(brand.dataownerRoles).some(
    (role) => role.isAdmin
  );
  const brandHasAtLeastOneLocationAdminRole = getSelectedRoles(brand.locationRoles).some(
    (role) => role.isAdmin
  );
  const brandHasConfiguredBrandAdminApp = brand.apps.some(({ key }) => key === AppType.BrandAdmin);

  return {
    brand: brandHasAtLeastOneBrandAdminRole
      ? ConfigurationStatus.Complete
      : ConfigurationStatus.Incomplete,
    dataowner: brandHasAtLeastOneDataownerAdminRole
      ? ConfigurationStatus.Complete
      : ConfigurationStatus.Incomplete,
    location: brandHasAtLeastOneLocationAdminRole
      ? ConfigurationStatus.Complete
      : ConfigurationStatus.Incomplete,
    brandAdminApp: brandHasConfiguredBrandAdminApp
      ? ConfigurationStatus.Complete
      : ConfigurationStatus.Incomplete,
  };
}
