import { Button, Card, Divider, Stack, SxProps, Theme, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import KeyInfoLink from './KeyInfoLink';
import { messages } from '@dap-common/i18n';
import { EventInformation } from '@dap-sanity/types';
import { getDateRange, getFullAddress } from '@dap-sanity/utils';

const cardStyling: SxProps<Theme> = {
  p: 2,
};

export default function EventInformationCard({
  dateTimeRange,
  address,
  registration,
  extraInfo,
}: EventInformation) {
  const { t } = useTranslation(['common', 'events']);

  const hasExtraInfo =
    extraInfo?.link?.length || extraInfo?.person?.length || extraInfo?.text?.length;

  return (
    <Card color="primary" elevation={1} sx={cardStyling}>
      <Stack spacing={2} divider={<CustomDivider />}>
        <Typography variant="h4" component="h2">
          {t(messages.common.information.label)}
        </Typography>

        <>
          {dateTimeRange && (
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <CalendarMonthOutlinedIcon fontSize="small" color="primary" />
              {getDateRange(dateTimeRange)}
            </Typography>
          )}

          {address && (
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <PlaceOutlinedIcon fontSize="small" color="primary" />
              {getFullAddress(address)}
            </Typography>
          )}
        </>

        {hasExtraInfo && (
          <>
            {extraInfo.link?.map((link) => (
              <KeyInfoLink key={link.url} link={link} />
            ))}
            {extraInfo.person?.map((person) => (
              <Stack key={person.name}>
                <Typography variant="h6" component="h3">
                  {person.title}
                </Typography>
                <Typography variant="body2">{person.name}</Typography>
              </Stack>
            ))}
            {extraInfo.text?.map((text) => (
              <Stack key={text.value}>
                <Typography variant="h6" component="h3">
                  {text.title}
                </Typography>
                <Typography key={text.value} variant="body2">
                  {text.value}
                </Typography>
              </Stack>
            ))}
          </>
        )}

        {(registration?.registrationUrl || registration?.registrationEmail) && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            href={registration.registrationUrl || `mailto:${registration.registrationEmail}`}
            target={registration.registrationUrl ? '_blank' : '_self'}
          >
            {t(messages.events.registration, { ns: 'events' })}
          </Button>
        )}
      </Stack>
    </Card>
  );
}

const CustomDivider = styled(Divider)`
  width: 176px;
`;
