import { hasBrandadminAppAccess, isAdmin } from '@dap-admin/utils';
import { getModuleRoute, getMosaicModuleRoute, getOrderRoute, routes } from '@dap-common/consts';
import { useSelectedBrandKey, useUser } from '@dap-common/data-access';
import { useAppTFunction } from '@dap-common/i18n';
import { useGetBrandConfig } from '@dap-sanity/data-access';
import { ModuleType } from '@dap-sanity/types';
import { NavRoute, NavWithSubRoutes, SubRoute } from '@shared/components';
import {
  AdministrationIcon,
  BriefcaseIcon,
  CalendarIcon,
  HomeIcon,
  NewsIcon,
  SanityIcon,
} from '@shared/custom-icons';
import { useMemo } from 'react';
import { useLocation, useMatch } from 'react-router-dom';

export type Route = NavRoute | NavWithSubRoutes;

// ASO TODO Should these predefined brands be moved to a shared location
const SANITY_BRANDS = ['systemhus', 'nordbohus', 'blinkhus', 'mesterhus'];
// ASO TODO Should these predefined role keys be moved to a shared location
const SANITY_ROLE_KEYS = ['sanity-admin-hus', 'sanity-redaktor-hus'];

export const useNavRoutes = (): Route[] => {
  const userData = useUser();
  const brandKey = useSelectedBrandKey();

  const t = useAppTFunction();
  const { pathname } = useLocation();

  const brandConfiguration = useGetBrandConfig({ brandKey });

  const isBrandadminPage = useMatch(routes.brandadmin);
  const isBrandDetailsPage = useMatch(`${routes.brandadmin}/${routes.brandDetails}/*`);
  const isBrandadminOrganizationPage = useMatch(`${routes.brandadmin}/${routes.organizationView}`);
  const isBrandadminDataownerPage = useMatch(`${routes.brandadmin}/${routes.dataowner}/*`);
  const isBrandadminLocationPage = useMatch(`${routes.brandadmin}/${routes.location}/*`);
  const isBrandadminEmployeePage = useMatch(`${routes.brandadmin}/${routes.employee}/*`);
  const isBrandadminMePage = useMatch(`${routes.brandadmin}/${routes.me}/*`);
  const isSanityPage = useMatch(`${routes.sanity}/*`);

  // Match routes
  const homeRouteMatch = useMatch(routes.home);

  return useMemo(() => {
    const userHasBrandadminAccess = hasBrandadminAppAccess(userData);

    const userHasSanityAccess =
      userData.roles.some((role) => role.role.key === 'dap-sanity-editor') ||
      (userData.brands.some((brand) => SANITY_BRANDS.includes(brand.key)) &&
        userData.roles.some((role) => SANITY_ROLE_KEYS.includes(role.role.key)));

    const homeNavConfig: NavRoute = {
      title: t('common.navigation.dashboard'),
      Icon: HomeIcon,
      isActive: !!homeRouteMatch,
      to: routes.home,
    };

    const adminSubRoutes: SubRoute[] = isAdmin(userData, brandKey)
      ? [
          {
            title: t('common.navigation.brandadmin'),
            isActive:
              !!isBrandadminPage ||
              !!isBrandadminDataownerPage ||
              !!isBrandadminLocationPage ||
              !!isBrandadminEmployeePage,
            to: routes.brandadmin,
          },
          {
            title: t('common.navigation.orgview'),
            isActive: !!isBrandadminOrganizationPage,
            to: `${routes.brandadmin}/${routes.organizationView}`,
          },
          {
            title: t('common.navigation.brandDetails'),
            isActive: !!isBrandDetailsPage,
            to: `${routes.brandadmin}/${routes.brandDetails}`,
          },
          {
            title: t('common.navigation.me'),
            isActive: !!isBrandadminMePage,
            to: `${routes.brandadmin}/${routes.me}`,
          },
        ]
      : [];

    const brandadminNavConfig: NavWithSubRoutes | undefined = userHasBrandadminAccess
      ? {
          title: t('common.navigation.brandadmin'),
          Icon: AdministrationIcon,
          isActive: !isAdmin(userData, brandKey) && !!isBrandadminPage,
          to: routes.brandadmin,
          subRoutes: adminSubRoutes,
        }
      : undefined;

    const proffNavConfig: NavRoute[] | undefined = brandConfiguration?.[ModuleType.proffModule]
      ?.filter((proffModule) => proffModule.show)
      .map((proffModule) => ({
        title: proffModule.header,
        Icon: BriefcaseIcon,
        isActive: pathname.split('/').some((pathParam) => pathParam === proffModule.slug),
        to: getModuleRoute({ moduleSlug: proffModule.slug }),
      }));

    const fremdriftNavConfig: NavRoute[] | undefined = brandConfiguration?.[
      ModuleType.fremdriftModule
    ]
      ?.filter((fremdriftModule) => fremdriftModule.show)
      .map((fremdriftModule) => ({
        title: fremdriftModule.header,
        Icon: BriefcaseIcon,
        isActive: pathname.split('/').some((pathParam) => pathParam === fremdriftModule.slug),
        to: getModuleRoute({ moduleSlug: fremdriftModule.slug }),
      }))
      .sort((a, b) => (a.title < b.title ? 1 : -1));

    const mosaicNavConfig: NavRoute[] | undefined = brandConfiguration?.[ModuleType.mosaicModule]
      ?.filter((mosaicModule) => mosaicModule.show)
      .map((mosaicModule) => ({
        title: mosaicModule.header,
        Icon: BriefcaseIcon,
        isActive: pathname.split('/').some((pathParam) => pathParam === mosaicModule.slug),
        to: getMosaicModuleRoute({ moduleSlug: mosaicModule.slug }),
      }));

    const orderNavConfig: NavRoute[] | undefined = brandConfiguration?.[ModuleType.orderModule]
      ?.filter((orderModule) => orderModule.show)
      .map((orderModule) => ({
        title: orderModule.header,
        Icon: BriefcaseIcon,
        isActive: pathname.split('/').some((pathParam) => pathParam === orderModule.slug),
        to: getOrderRoute({ moduleSlug: orderModule.slug }),
      }));

    const calendarNavConfig: NavRoute | undefined = brandConfiguration?.[ModuleType.eventsModule]
      ?.show
      ? {
          title: t('events.calendar'),
          Icon: CalendarIcon,
          isActive: pathname.split('/').some((pathParam) => pathParam === routes.events),
          to: routes.events,
        }
      : undefined;

    const newsNavConfig: NavRoute | undefined = brandConfiguration?.[ModuleType.newsModule]?.show
      ? {
          title: t('newsArticles.news'),
          Icon: NewsIcon,
          isActive: pathname.split('/').some((pathParam) => pathParam === routes.news),
          to: routes.news,
        }
      : undefined;

    const servicesNavConfig: NavRoute | undefined = brandConfiguration?.[ModuleType.servicesModule]
      .show
      ? {
          title: t('services.serviceCatalogName.singular'),
          Icon: BriefcaseIcon,
          isActive: pathname.split('/').some((pathParam) => pathParam === routes.services),
          to: routes.services,
        }
      : undefined;

    const helpCentreNavConfig: NavRoute | undefined = brandConfiguration?.[
      ModuleType.helpCentreModule
    ]?.show
      ? {
          title: t('helpCentre.title'),
          Icon: BriefcaseIcon,
          isActive: pathname.split('/').some((pathParam) => pathParam === routes.helpCentre),
          to: routes.helpCentre,
        }
      : undefined;

    const sanityMainNavConfig: NavRoute | undefined = userHasSanityAccess
      ? {
          title: 'Sanity',
          Icon: SanityIcon,
          isActive: !!isSanityPage,
          to: `${routes.sanity}`,
        }
      : undefined;

    // Merge all configs into one object, and remove undefined values
    return [
      homeNavConfig,
      brandadminNavConfig,
      ...(proffNavConfig || []),
      ...(fremdriftNavConfig || []),
      ...(mosaicNavConfig || []),
      ...(orderNavConfig || []),
      calendarNavConfig,
      newsNavConfig,
      servicesNavConfig,
      helpCentreNavConfig,
      sanityMainNavConfig,
    ].filter((navConfig) => !!navConfig) as Route[];
  }, [
    brandConfiguration,
    brandKey,
    homeRouteMatch,
    isBrandDetailsPage,
    isBrandadminDataownerPage,
    isBrandadminEmployeePage,
    isBrandadminLocationPage,
    isBrandadminOrganizationPage,
    isBrandadminPage,
    isSanityPage,
    pathname,
    t,
    userData,
  ]);
};
