export enum BlockType {
  NewsList = 'newsList',
  LinkList = 'linkList',
  LinkListItem = 'linkListItem',
  EventList = 'eventList',
  LegacyBanner = 'legacyBanner',
  LegacyImageBanner = 'legacyImageBanner',
  LegacyMGABanner = 'legacyMGABanner',
  LegacyIntranetBanner = 'legacyIntranetBanner',
  LegacyProffBanner = 'legacyProffBanner',
  WorkplaceFeed = 'workplaceFeed',
  Leads = 'leads',
  ServiceMessage = 'serviceMessage',
  FeaturedArticle = 'featuredArticle',
}
