import { Stack, Box, Typography } from '@mui/material';
import { SnapItem, SnapList } from 'react-snaplist-carousel';
import { useCarousel } from '@shared/hooks';
import { PaginationArrows } from '../Pagination/PaginationArrows';

interface Props {
  title?: string;
  cards: JSX.Element[];
}

export function CardsCarousel({ title, cards }: Props) {
  const { snapListRef, firstItemIsVisible, lastItemIsVisible, scrollForward, scrollBack } =
    useCarousel(cards.length);

  if (cards.length === 0) {
    return (
      <>
        <Typography variant="h4" component="h2">
          {title}
        </Typography>
        <Typography>Listen er tom.</Typography>
      </>
    );
  }

  const showPaginationArrows = !firstItemIsVisible || !lastItemIsVisible;

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: 3 }}
      >
        <Typography variant="h4" component="h2">
          {title}
        </Typography>
        {showPaginationArrows && (
          <PaginationArrows
            disableNext={lastItemIsVisible}
            disablePrevious={firstItemIsVisible}
            handleNext={scrollForward}
            handlePrevious={scrollBack}
          />
        )}
      </Stack>
      <Box sx={{ overflow: 'hidden', containerType: 'inline-size' }}>
        <SnapList ref={snapListRef} direction="horizontal">
          {cards.map((item, index) => (
            <SnapItem
              snapAlign="center"
              key={index}
              margin={{
                left: index === 0 ? '0px' : '8px',
                right: index === cards.length - 1 ? '0px' : '8px',
              }}
            >
              {item}
            </SnapItem>
          ))}
        </SnapList>
      </Box>
    </>
  );
}
