import React, { PropsWithChildren, ReactNode } from 'react';
import { Link, To } from 'react-router-dom';

interface Props {
  to: To;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLSpanElement>;
}

function InternalLink({
  to,
  startIcon,
  endIcon,
  onClick,
  children,
}: PropsWithChildren<Props>) {
  return (
    <Link to={to} onClick={onClick}>
      {startIcon}
      <span>{children}</span>
      {endIcon}
    </Link>
  );
}

export default InternalLink;
