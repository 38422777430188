import { Stack, Typography, Card, Box } from '@mui/material';
import { PaginationArrows } from '../../components/Pagination/PaginationArrows';
import { SnapItem, SnapList } from 'react-snaplist-carousel';
import { useCarousel } from '@shared/hooks';
import { LatestArticleItem } from './LatestArticleItem';

interface Props {
  title: string;
  articles: LatestArticleItem[];
}

export function LatestArticles({ title, articles }: Props) {
  const { snapListRef, firstItemIsVisible, lastItemIsVisible, scrollForward, scrollBack } =
    useCarousel(articles.length);

  return (
    <Card
      sx={{
        padding: 0,
        position: 'relative',
      }}
    >
      <Stack direction="row" alignItems="stretch">
        <Stack
          justifyContent="center"
          alignItems="center"
          paddingInline={5}
          // @ts-ignore Why does this not work?
          sx={{ background: ({ palette }) => palette.primary[800] }}
        >
          <Typography variant="h6" component="h2" color="common.white">
            {title}
          </Typography>
        </Stack>
        <Box
          sx={{
            flex: 1,
            overflow: 'hidden',
            containerType: 'inline-size',
          }}
        >
          <SnapList ref={snapListRef} direction="horizontal">
            {articles.map((article, index) => (
              <SnapItem
                snapAlign="center"
                key={index}
                margin={{
                  left: index === 0 ? '0px' : '1px',
                  right: index === articles.length - 1 ? '100px' : '0px',
                }}
              >
                <LatestArticleItem key={article.url} {...article} />
              </SnapItem>
            ))}
          </SnapList>
        </Box>
      </Stack>
      <Stack
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
          bottom: 0,
          paddingRight: 1,
          // boxShadow: 'inset 0 0 0 1px rebeccapurple',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          width: '104px',
          background: 'linear-gradient(90deg, transparent 0%, #fff2 25%, #fffb 60%, #fffe 100%)',
        }}
      >
        <PaginationArrows
          disableNext={lastItemIsVisible}
          disablePrevious={firstItemIsVisible}
          handleNext={scrollForward}
          handlePrevious={scrollBack}
          hideWhenDisabled
        />
      </Stack>
    </Card>
  );
}
