import { Box, SxProps, Typography, TypographyProps, Theme } from '@mui/material';
import React, { ReactNode, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  label: string;
  value: ReactNode;
  labelPlacement?: 'start' | 'top';
  alignItems?: 'baseline' | 'center';
  justifyContent?: 'baseline' | 'space-between';
  flexGrow?: number;
  sx?: SxProps<Theme>;
  labelTypographyProps?: TypographyProps;
  valueTypographyProps?: TypographyProps;
}

const LabelValue: React.FunctionComponent<Props> = ({
  label,
  value,
  labelPlacement = 'start',
  alignItems = 'center',
  justifyContent = 'baseline',
  flexGrow = 1,
  sx = {},
  labelTypographyProps = {},
  valueTypographyProps = {},
}) => {
  const labelId = useMemo(() => uuidv4(), []);

  return (
    <Box
      display="flex"
      flexDirection={labelPlacement === 'start' ? 'row' : 'column'}
      justifyContent={justifyContent}
      alignItems={alignItems}
      flexGrow={flexGrow}
      sx={sx}
    >
      <Typography
        sx={{
          margin: labelPlacement === 'start' ? '0 0.5rem 0 0' : '0',
          fontWeight: 'bold',
          whiteSpace: 'nowrap',
        }}
        {...{ ...labelTypographyProps, variant: labelTypographyProps.variant ?? 'body3' }}
      >
        <label id={labelId}>{label}</label>
      </Typography>
      <Typography variant="body3" aria-labelledby={labelId} {...valueTypographyProps}>
        {value}
      </Typography>
    </Box>
  );
};

export default LabelValue;
