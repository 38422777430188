import { useAppTFunction } from '@dap-common/i18n';
import { ChevronLeft } from '@mui/icons-material';
import { Collapse, Stack, Typography } from '@mui/material';
import { CenterElement, ContainedButton, Paper, TextButton } from '@shared/components';
import { useDisclosure } from '@shared/hooks';
import React from 'react';

interface Props {
  icon?: React.ReactNode;
  title: string;
  subtitle: string;
  actionButtonLabel?: string;
  onActionButtonClick?: () => void;
  errorMessage?: string;
}

export default function ErrorTemplate({
  icon,
  title,
  subtitle,
  actionButtonLabel,
  onActionButtonClick,
  errorMessage,
}: Props) {
  const t = useAppTFunction();
  const showMore = useDisclosure();
  return (
    <CenterElement flexDirection={'column'}>
      {icon && (
        <Stack
          height="128px"
          width="128px"
          borderRadius="50%"
          bgcolor={({ palette }) => palette.warning[100]}
          alignItems="center"
          justifyContent="center"
          fontSize="48px"
          marginBottom={4}
          sx={{ '> svg': { fontSize: '64px', color: ({ palette }) => palette.warning[800] } }}
        >
          {icon}
        </Stack>
      )}

      <Typography variant="h1" gutterBottom>
        {title}
      </Typography>

      <Typography paragraph>{subtitle}</Typography>

      {actionButtonLabel && onActionButtonClick && (
        <Stack my={2}>
          <ContainedButton onClick={onActionButtonClick}>{actionButtonLabel}</ContainedButton>
        </Stack>
      )}

      {errorMessage ? (
        <>
          <Stack my={2}>
            <TextButton
              size="small"
              onClick={() => showMore.onToggle()}
              endIcon={
                <ChevronLeft
                  sx={{
                    transform: showMore.isOpen ? 'rotate(90deg)' : 'rotate(-90deg)',
                    transition: 'transform 0.2s ease-in-out',
                  }}
                />
              }
            >
              {t('order.order.showMore')}
            </TextButton>
          </Stack>

          <Collapse in={showMore.isOpen}>
            <Paper sx={{ py: 3, px: 4, backgroundColor: ({ palette }) => palette.primary.A100 }}>
              <Typography variant="body2">{errorMessage}</Typography>
            </Paper>
          </Collapse>
        </>
      ) : null}
    </CenterElement>
  );
}
